import * as React from 'react'
import { injectIntl, addLocaleData, Locale } from 'react-intl'
import locale from '../../data/onboarding.json'
import { Link } from 'gatsby'
import {
  getPersonalData,
  postPersonalData,
  getMembershipData,
  getCancellationCauses,
  cancelMembership,
  cancelHelp,
  getMembershipStatus,
} from 'apiConfig/apiMethods'
import Loader from 'components/loader/loader'
import FreeTextInput from 'components/onboarding/FreeTextInput/FreeTextInput'
import Button from 'components/button/Button'
import * as Redux from 'redux'
import { AuthState, AuthActionTypes } from 'store/auth/types'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { navigate } from '@reach/router'
import Info from '../../images/Icons/info.svg'
import ArrowForward from '../../images/Icons/arrow_forward.svg'
import { flattenObject } from '../../utils/tools'
import {
  extractPlaceholderIdsFromDesc,
  splitDescOnPlaceholders,
  getSummaryBasedOnMembershipData,
} from '../../config/helpers'
import { PoseGroup } from 'react-pose'
import { SignupInput } from './calendar/animations'
import Modal from 'components/modal/Modal'
import { UserData, MembershipData, MembershipStatus } from '../../config/types'
import ArrowDown from '../../images/Icons/arrow_drop_down.svg'
import { MemberRoles } from 'components/onboarding/bankID/types.js'
import { CONTACT_DIK_DIREKT_LINK } from '../../config/constants'

const flattedLocale = flattenObject(locale)

export interface CancellationCause {
  id: string
  name: string
  type: string
}

interface ModalOptions {
  visible: boolean
  id?: 'cancelMembership' | 'cancelHelp'
}

interface StateProps {
  auth: AuthState
  role: MemberRoles
}

interface DispatchProps {
  requestSignOut: () => void
}

interface Props extends StateProps, DispatchProps {
  intl: any
}

interface State {
  userData?: UserData
  membershipData?: MembershipData
  membershipDesc?: string
  isLoading: boolean
  modalOptions: ModalOptions
  selectedCancellationCause: string
  cancellationCauses: CancellationCause[]
  memberStatus?: MembershipStatus
  cancelConfirmation?: boolean
  cancelProgress?: boolean
}

class Uppgifter extends React.Component<Props, State> {
  state: State = {
    userData: undefined,
    membershipData: undefined,
    membershipDesc: undefined,
    isLoading: false,
    modalOptions: {
      visible: false,
    },
    selectedCancellationCause: '',
    cancellationCauses: [],
    cancelConfirmation: false,
    cancelProgress: false,
  }

  componentDidMount() {
    this.fetchUserData()
    this.fetchUserMembershipDetails()
    this.getCancellationCauses()
  }

  fetchUserData = async () => {
    const data = await getPersonalData()

    if (!data) {
      return null
    }

    this.setState({ userData: data })
  }

  fetchUserMembershipDetails = async () => {
    const [data, memberStatus] = await Promise.all([getMembershipData(), getMembershipStatus()])

    if (!data) {
      return null
    }

    const occupationType = 'employed'
    const navDesc = flattedLocale[`nav.bli-medlem.desc.${occupationType}`] as string
    const placeholders = extractPlaceholderIdsFromDesc(navDesc)
    let membershipDesc = getSummaryBasedOnMembershipData(navDesc, placeholders, data)

    if (data.hasAkassa) {
      membershipDesc = `${membershipDesc} Jag är med i Akademikernas a-kassa.`
    } else {
      membershipDesc = `${membershipDesc} Jag är inte med i Akademikernas a-kassa.`
    }

    this.setState({ membershipData: data, membershipDesc, memberStatus })
  }

  hideContactInfo() {
    return true; //Hide everything
    if (process.env.NODE_ENV === "production")
    {
      return true;
    }

    const currentMinute = new Date().getMinutes();
    return currentMinute % 2 === 0; // Toggle every other minute
  }

  getCancellationCauses = async () => {
    const data = await getCancellationCauses()

    if (!data) {
      return null
    }

    this.setState({ cancellationCauses: data })
  }

  renderUserInfo = () => {
    if (!this.state.userData) {
      return (
        <div className="userInfoLoaderContainer">
          <Loader color="dark" fade={true} />
        </div>
      )
    }

    const { formatMessage } = this.props.intl
    const { firstName, lastName, addressLine1, postalCode, city, ocr } = this.state.userData.data

    return (
      <div>
        <div>
          <p className="myPages-uppgifter-label u-marginBz">
            {formatMessage({ id: 'uppgifter.credentials.name' })}
          </p>
          <p className="u-marginTz">
            {firstName} {lastName}
          </p>
        </div>
        <div>
          <p className="myPages-uppgifter-label u-marginBz">
            {formatMessage({ id: 'uppgifter.credentials.adress' })}
          </p>
          <p className="u-marginTz">
            {addressLine1}
            <br />
            {postalCode} {city}
          </p>
        </div>
        <div className="myPages-warning-label tab">
          <Info className="u-marginRsm" />
          <p>
            Gå till{' '}
            <a href="https://skatteverket.se/" target="_blank" className="Link">
              skatteverket.se
            </a>{' '}
            för att ändra din adress.
          </p>
        </div>
      </div>
    )
  }

  renderUserContact = () => {

    if (!this.state.userData) {
      return (
        <div className="userInfoLoaderContainer">
          <Loader color="dark" fade={true} />
        </div>
      )
    }

    const { formatMessage } = this.props.intl
    const { phone, email } = this.state.userData.data.contactInfo

    if(this.hideContactInfo())
      {
        return (
          <div>
            <div>
              <p className="myPages-uppgifter-label u-marginBz">
                {formatMessage({ id: 'inputlabels.phone' })}
              </p>
              <p className="u-marginTz">
                {phone}
              </p>
            </div>
            <div>
              <p className="myPages-uppgifter-label u-marginBz">
                {formatMessage({ id: 'inputlabels.email' })}
              </p>
              <p className="u-marginTz">
                {email}
              </p>
            </div>   
            <div>
              <p style={{ marginTop: 50, fontSize: 14 }}>
                Pga ett pågående systembyte går det inte att uppdatera sina uppgifter just nu. Kontakta DIK via {' '}
                <a href="mailto:fraga@dik.se" className="Link">fraga@dik.se</a>{' '} eller ring <a href="tel:+46848004000" className="Link">08-480 040 00</a> {' '} för att ändra dessa.
              </p>
            </div>

            {/* <div className="myPages-warning-label tab">
              <Info className="u-marginRsm" />
              <p>
                Gå till{' '}
                <a href="https://skatteverket.se/" target="_blank" className="Link">
                  skatteverket.se
                </a>{' '}
                för att ändra din adress.
              </p>
            </div> */}
          </div>
        )
      }

    return (
      <div>
        <FreeTextInput
          hasButton={false}
          onUpdated={(text) => this.onChangeText('email', text)}
          currentValue={email}
          id="email"
        />

        <FreeTextInput
          hasButton={false}
          onUpdated={(text) => this.onChangeText('phone', text)}
          currentValue={phone}
          id="phone"
        />

        <div className="userContactButton">
          <Button
            isLoading={this.state.isLoading}
            onClick={() => this.postPersonalData()}
            type="submit"
            className={`button button--normal orange`}
          >
            {formatMessage({ id: 'buttons.save' })}
          </Button>
        </div>
      </div>
    )
  }

  renderUserMembershipData = () => {
    const { role } = this.props
    const { membershipData } = this.state

    if (!membershipData) {
      return (
        <div className="userInfoLoaderContainer">
          <Loader color="dark" fade={true} />
        </div>
      )
    }

    if (role === 'Start') {
      return (
        <>
          <p>Du testar just nu DIK som gratismedlem.</p>
          <p>
            Kontakta kundtjänst på{' '}
            <a href={CONTACT_DIK_DIREKT_LINK} className="Link">
              DIK Direkt
            </a>{' '}
            för att uppgradera ditt medlemskap.
          </p>
        </>
      )
    }

    const {
      occupationType,
      industry,
      jobTitle,
      workplace,
      salary,
      hasAkassa,
      educationInstitution,
      educationProgramme,
      educationSpecialization,
    } = membershipData

    return (
      <>
        {occupationType && <p>Sysselsättning: {occupationType}</p>}
        {educationSpecialization && educationSpecialization.name ? (
          <p>Inriktning: {educationSpecialization.name}</p>
        ) : (
          industry && industry.name && <p>Bransch: {industry.name}</p>
        )}
        {educationProgramme && educationProgramme.name ? (
          <p>Program: {educationProgramme.name}</p>
        ) : jobTitle && jobTitle.name ? (
          <p>Jobbtitel: {jobTitle.name}</p>
        ) : null}
        {educationInstitution && educationInstitution.name ? (
          <p>Universitet/Högskola/Skola: {educationInstitution.name}</p>
        ) : workplace && workplace.name ? (
          <p>Arbetsplats: {workplace.name}</p>
        ) : null}
        {salary ? <p>Lön: {salary}kr</p> : null}
        <p>
          {hasAkassa
            ? 'Är med i Akademikernas a-kassa.'
            : 'Eventuellt medlemskap i a-kassa betalas inte via DIK.'}
        </p>
        <p style={{ marginTop: 50, fontSize: 14 }}>
          Behöver du ändra några uppgifter under Min status? Kontakta{' '}
          <a href="mailto:fraga@dik.se" className="Link">
            DIK Direkt
          </a>
          .
        </p>
      </>
    )

    // if(!this.state.membershipDesc) {
    //   return (
    //     <div className="userInfoLoaderContainer">
    //       <Loader color="dark" fade={true} />
    //     </div>
    //   )
    // }

    // return this.state.membershipDesc
  }

  onChangeText = (field: 'email' | 'phone', text: string) => {
    const { userData } = this.state

    if (!userData) {
      return null
    }

    this.setState({
      userData: {
        ...userData,
        data: {
          ...userData.data,
          contactInfo: {
            ...userData.data.contactInfo,
            [field]: text,
          },
        },
      },
    })
  }

  postPersonalData = () => {
    const { userData } = this.state

    if (!userData) {
      return null
    }

    this.setState({ isLoading: true })

    postPersonalData(userData.data.contactInfo)
      .then((res) => {
        this.setState({ isLoading: false })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
      })
  }

  cancelMembership = () => {
    this.setState({ cancelProgress: true })
    cancelMembership(this.state.selectedCancellationCause)
      .then(() => {
        this.setState({ cancelProgress: false })
        this.setState({ cancelConfirmation: true })
      })
      .catch((error) => console.log(error))
  }

  cancelHelp = () => {
    cancelHelp()
      .then(() => {
        this.setState({ modalOptions: { visible: false } })
        this.fetchUserMembershipDetails()
      })
      .catch((error) => console.log(error))
  }

  toggleModal = (modalOptions: ModalOptions) => {
    this.setState({ modalOptions })
  }

  renderModal = () => {
    const {
      modalOptions,
      cancellationCauses,
      selectedCancellationCause,
      cancelConfirmation,
      cancelProgress,
    } = this.state
    const cancelValidated = selectedCancellationCause.length > 0

    if (!modalOptions.visible) {
      return null
    }

    switch (modalOptions.id) {
      case 'cancelHelp':
        return (
          <Modal key={modalOptions.id} onClose={() => this.toggleModal({ visible: false })}>
            <h4>
              Är du verkligen säker på att du vill avsluta försäkringen Min Privatjurist som ges i
              samarbete med HELP Försäkring?{' '}
            </h4>
            <h4 className="u-marginBsm u-marginTz">
              <a className="Link" href="/medlemskapet/det-har-for-du-som-medlem/min-privatjurist">
                Läs mer
              </a>
            </h4>
            <div className="modalButtonContainer u-marginTz">
              <Button
                className="button button--normal blue"
                onClick={() => this.toggleModal({ visible: false })}
              >
                Avbryt
              </Button>
              <Button className={`button button-border blue`} onClick={() => this.cancelHelp()}>
                Avsluta
              </Button>
            </div>
          </Modal>
        )

      case 'cancelMembership':
        return (
          <Modal key={modalOptions.id} onClose={() => this.toggleModal({ visible: false })}>

            {this.hideContactInfo() ? (
                <>
                <h4 className="modalTitle">Avsluta medlemskap</h4>
                <p>
                  Pga ett pågående systembyte går det inte att avsluta medlemskap på dik.se just nu. Kontakta DIK via {' '}
                  <a href="mailto:fraga@dik.se" className="Link">fraga@dik.se</a>{' '} eller ring <a href="tel:+46848004000" className="Link">08-480 040 00</a> {' '} för att avsluta.
                </p>
              </>
            ) : 
            (
              <>
            {!cancelConfirmation ? (
              <>
                <h4 className="modalTitle">Är du säker på att du vill avsluta ditt medlemskap?</h4>
                <div className="u-posRelative cancellationSelect">
                  <select
                    className="myPages-select"
                    onChange={(e) => {
                      this.setState({ selectedCancellationCause: e.target.value })
                    }}
                  >
                    <option disabled selected>
                      -- Välj en orsak --
                    </option>
                    {cancellationCauses.map((data) => {
                      return <option value={data.id}>{data.name}</option>
                    })}
                  </select>
                  <ArrowDown className="myPages-select-dropdown" />
                </div>
                <div className="modalButtonContainer">
                  <Button
                    className="button button--normal blue"
                    onClick={() => this.toggleModal({ visible: false })}
                  >
                    Avbryt
                  </Button>
                  <Button
                    disabled={!cancelValidated}
                    className={`button button-border blue ${cancelValidated ? '' : 'disabled'}`}
                    onClick={() => this.cancelMembership()}
                    isLoading={cancelProgress}
                    loaderColor="dark"
                  >
                    Avsluta
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h4 className="modalTitle">Vad tråkigt att du lämnar oss!</h4>
                <p>
                  En bekräftelse skickas till din e-postadress med information om ditt utträde.
                  Tveka inte att kontakta oss på{' '}
                  <a className="Link" href="mailto:fraga@dik.se">
                    fraga@dik.se
                  </a>{' '}
                  ifall du har funderingar eller ångrat dig. Du är alltid välkommen tillbaka.
                </p>
                <div className="modalConfirmationBtnContainer">
                  <Button
                    className="button button--normal blue "
                    onClick={() => navigate('/mina-sidor')}
                  >
                    Mina sidor
                  </Button>
                </div>
              </>
            )}
            </>
          )}
          </Modal>
        )

      default:
        return null
    }
  }

  render() {
    const { formatMessage } = this.props.intl
    const { membershipData } = this.state

    return (
      <>
        <div className="Page-container">
          <div className="u-sizeFull u-lg-size1of2">
            <h1>{formatMessage({ id: 'uppgifter.headline' })}</h1>
            <p>{formatMessage({ id: 'uppgifter.preamble' })}</p>
          </div>
        </div>
        <div className="u-colorBgWhite u-flexGrow">
          <div className="Page-container">
            <div className="myPages-uppgifter">
              <section className="">
                
                <div className="Grid Grid--gutterA u-marginTz">
                  <div className="Grid-cell u-sizeFull u-md-size1of3">
                    <p className="myPages-label">
                      {formatMessage({ id: 'uppgifter.credentials.headline' })}
                    </p>
                    {this.renderUserInfo()}
                  </div>

                  <div className="Grid-cell u-sizeFull u-md-size1of3">
                    <p className="myPages-label">
                      {formatMessage({ id: 'uppgifter.contact.headline' })}
                    </p>
                    {this.renderUserContact()}
                  </div>

                  <div className="Grid-cell u-sizeFull u-md-size1of3">
                    <p className="myPages-label">
                      {formatMessage({ id: 'uppgifter.status.headline' })}
                    </p>
                    <div>
                      <p className="myPages-uppgifter-summary">{this.renderUserMembershipData()}</p>
                    </div>
                  </div>
                </div>
                

                <ul className="List u-flex u-flexDirectionColumn u-flexAlignStart">
                  {/* {membershipData &&
                    membershipData.hasHelpService &&
                    !membershipData.isHelpServiceCancelled && (
                      <li>
                        <a
                          onClick={() =>
                            this.toggleModal({
                              visible: true,
                              id: 'cancelHelp',
                            })
                          }
                          className="Link u-inlineBlock u-marginTsm"
                          style={{ lineHeight: 'normal', cursor: 'pointer' }}
                        >
                          Avsluta Min Privatjurist{' '}
                          <ArrowForward
                            width="16"
                            height="16"
                            className="u-fillBlue u-alignMiddle"
                          />
                        </a>
                      </li>
                    )} */}
                  <li>
                    <Link
                      onClick={() => this.requestSignOut()}
                      className="Link u-inlineBlock u-marginTsm"
                      style={{ lineHeight: 'normal' }}
                      to="/"
                    >
                      Logga ut{' '}
                      <ArrowForward width="16" height="16" className="u-fillBlue u-alignMiddle" />
                    </Link>
                  </li>
                  <li>
                    {this.state.memberStatus?.message.indexOf('Exiting') ?? -1 === -1 ? (
                      <a
                        onClick={() =>
                          this.toggleModal({
                            visible: true,
                            id: 'cancelMembership',
                          })
                        }
                        className="Link u-inlineBlock u-marginTsm"
                        style={{ lineHeight: 'normal', cursor: 'pointer' }}
                      >
                        Avsluta medlemskap{' '}
                        <ArrowForward width="16" height="16" className="u-fillBlue u-alignMiddle" />
                      </a>
                    ) : (
                      <span className="u-inlineBlock u-marginTsm">
                        Ditt DIK-medlemskap avslutas. För frågor, kontakta{' '}
                        <a href="mailto:fraga@dik.se" className="Link">
                          fraga@dik.se
                        </a>
                        .
                      </span>
                    )}
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
        {this.renderModal()}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  requestSignOut: () => dispatch({ type: AuthActionTypes.SIGNOUT_USER }),
})

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    auth: state.auth,
    role: state.bankId.role,
  }
}

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Uppgifter))
